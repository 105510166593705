import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AppDispatch } from '../../app/store';
import { salesforceAuthComplete, salesforceAuthStart, selectAuthStatus } from './authSlice';
import { Login } from './Login';

type NavigationState = { from?: Location } | undefined;

export const LoginContainer: React.FC = () => {
  const dispatch : AppDispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const [authPending, setAuthPending] = useState(false);
  const onLogin = (isSandbox: boolean) => async () => {
    const url = await dispatch(salesforceAuthStart(isSandbox));

    if (url) {
      window.location.href = url;
    }
  }

  const { pathname, state } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const code = searchParams.get('code');

    if (code) {
      setAuthPending(true);
      const sfState = new URLSearchParams(decodeURI(searchParams.get('state') ?? ''));

      dispatch(salesforceAuthComplete(code, sfState.get('sandbox') === 'true'));

      searchParams.delete('code');
      // Strip auth code from navigation history.
      navigate(`${pathname}?${searchParams}`, { replace: true, state });
    }

    if (authStatus === 'user') {
      const from = (state as NavigationState)?.from?.pathname || '/';

      navigate(from, { replace: true });
    }
  }, [searchParams, dispatch, navigate, pathname, state, authStatus]);

  return (<Login onLogin={onLogin(false)} onSandboxLogin={onLogin(true)} pending={authPending} />);
};
