import React from 'react';
import { ReportGroup, ScoreLevelMessages } from '../../types';
import { aggregatedScore } from '../../utils';
import { Card } from '../Card';
import { GroupProgress } from './GroupProgress';
import { CardProps } from '../types';
import { ScoreDetail } from './ScoreDetail';
import { ScoreDetailsList } from './ScoreDetailsList';

type TotalScoreCardProps = CardProps & {
  title: string;
  groupScore?: ReportGroup;
  messages: {[key: string]: ScoreLevelMessages};
};

export const GroupScoreCard: React.FC<TotalScoreCardProps> = ({ title, icon, groupScore, messages }) => {
  return (
    <Card title={title} icon={icon}>
      { groupScore && <GroupProgress value={aggregatedScore(groupScore)} /> }

      { groupScore && <ScoreDetailsList>
        { Object.entries(groupScore).map(
          ([key, score]) => <ScoreDetail key={key} score={score} messages={messages[key]} />
        ) }
      </ScoreDetailsList>}
    </Card>
  );
}
