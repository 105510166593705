import React from 'react';
import { Icon } from '../../../icon/Icon';
import { IconName, IconSize } from '../../../icon/types';
import { Score, ScoreLevel, ScoreLevelMessages } from '../../types';

import styles from './GroupScore.module.scss';

type Props = {
  score: Score;
  messages?: ScoreLevelMessages;
};

const getIconName = (level: ScoreLevel): IconName => {
  switch (level) {
    case ScoreLevel.Pass:
      return 'check-circle'
    case ScoreLevel.Warning:
      return 'warning';
    case ScoreLevel.Danger:
      return 'close-circle';
  }
}

const getIconClass = (level: ScoreLevel): string => {
  const classes = [styles.ScoreDetailIcon];

  switch (level) {
    case ScoreLevel.Pass:
      classes.push(styles.ScoreDetailIconPass);
      break;
    case ScoreLevel.Warning:
      classes.push(styles.ScoreDetailIconWarning);
      break;
    case ScoreLevel.Danger:
      classes.push(styles.ScoreDetailIconDanger);
      break;
  }

  return classes.join(' ');
}

export const ScoreDetail: React.FC<Props> = ({ score, messages }) => {
  const message = messages?.[score.level];

  if (!message) {
    // Don't show details if message is not set.
    return null;
  }

  return (
    <div className={styles.ScoreDetail}>
      <Icon className={getIconClass(score.level)} name={getIconName(score.level)} size={IconSize.Medium} />
      <span>{message}</span>
    </div>
  );
}
