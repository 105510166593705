import React from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '../navbar/Navbar';

import styles from './Layout.module.scss';

export const WithNav: React.FC = () => {
  return (
    <>
      <Navbar />

      <div className={`${styles.Container} ${styles.Container_WithNav}`}>
        <Outlet />
      </div>
    </>
  );
}
