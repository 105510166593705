import React from 'react';
import { IconName, IconSize } from './types';

import styles from './Icon.module.scss';

type IconProps = {
  name: IconName;
  size?: IconSize;
  className?: string;
};

export const Icon: React.FC<IconProps> = ({ name, className, size = IconSize.Medium}) => {
  const iconLink = `#icon-${name}`;
  const iconClass = () => {
    const classes = [styles.icon, styles[`icon--${size}`], styles[`icon--${name}`]];

    if (className) {
      classes.push(className);
    }

    return classes.join(' ');
  };

  return (
    <svg className={iconClass()} viewBox="0 0 24 24">
      <use xlinkHref={iconLink}></use>
    </svg>
  )
};
