import { scoreStyles } from './constants';
import { OrganizationReport, ReportGroup, ScoreStyle, ScoreValue } from './types';

export function getScoreStyle(score: ScoreValue): ScoreStyle {
  const scorePercentage = score.score / score.maxScore;
  const sortedStyles = Object.values(scoreStyles).sort((a, b) => b.grade - a.grade);
  const stylesCount = sortedStyles.length;

  return sortedStyles.find((style) => {
    return scorePercentage >= style.grade / stylesCount;
  }) as ScoreStyle;
}

export function aggregatedScore(group: { [key: string]: ScoreValue }): ScoreValue {
  return Object.values(group).reduce<ScoreValue>(
    (groupTotals, score): ScoreValue => {
      return {
        score: groupTotals.score + score.score,
        maxScore: groupTotals.maxScore + score.maxScore,
      };
    },
    { score: 0, maxScore: 0 },
  )
}

export function calcTotalScore(report: OrganizationReport): ScoreValue | undefined {
  return Object.values(report.groups).reduce(
    (totals, group: ReportGroup): ScoreValue => {
      const groupScore = aggregatedScore(group);

      return {
        score: totals.score + groupScore.score,
        maxScore: totals.maxScore + groupScore.maxScore,
      };
    },
    { score: 0, maxScore: 0 } as ScoreValue,
  );
}
