import React from 'react';

import styles from './Header.module.scss';

export const Header: React.FC = () => {
  return (
    <div className={styles.DashboardHeader}>
      Your <span className={styles.DashboardHeader_Primary}>Dashboard</span>
    </div>
  );
}
