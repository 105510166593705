import React from 'react';
import { Cards } from './cards/Cards';
import { GroupScoreCard } from './cards/group-score/GroupScoreCard';
import { TotalScoreCard } from './cards/total-score/TotalScoreCard';
import { reportDisplaySettings, reportScoreMessages } from './constants';
import { Footer } from './footer/Footer';
import { Header } from './header/Header';
import { Overlay } from './overlay/Overlay';
import { GroupDisplaySettings, OrganizationReport } from './types';
import { calcTotalScore } from './utils';

import styles from './Dashboard.module.scss';

export type DashboardProps = {
  isLoading?: boolean;
  report: OrganizationReport | null;
};

export const Dashboard: React.FC<DashboardProps> = ({ isLoading, report }) => {
  return (
    <div className={styles.DashboardContainer}>
      <Header />

      { isLoading && <Overlay /> }

      { isLoading || <>
        <Cards>
          { report && <TotalScoreCard value={calcTotalScore(report)} /> }

          { (Object.entries(reportDisplaySettings) as Array<[keyof OrganizationReport['groups'], GroupDisplaySettings]>)
            .map(([groupKey, groupSettings]) => {
              const groupScore = report?.groups?.[groupKey];

              if (!groupScore) {
                return null;
              }

              return <GroupScoreCard
                key={groupKey}
                title={groupSettings.title}
                icon={groupSettings.icon}
                groupScore={groupScore}
                messages={reportScoreMessages[groupKey]}
              />;
            })
          }
        </Cards>

        { report && <Footer meta={report?.meta} org={report?.organization} /> }
      </> }
    </div>
  );
}
