import { configureStore, ThunkAction, Action, MiddlewareArray } from '@reduxjs/toolkit';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import authReducer from '../features/auth/authSlice';
import apiReducer from '../features/api/apiSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';

const loadState = () => {
  return JSON.parse(localStorage.getItem('store') ?? '{}');
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    api: apiReducer,
    dashboard: dashboardReducer,
  },
  middleware: new MiddlewareArray().concat(thunk as ThunkMiddleware),
  preloadedState: loadState(),
});

const saveState = () => {
  const state = store.getState();

  localStorage.setItem('store', JSON.stringify({
    auth: state.auth,
  }))
}

store.subscribe(saveState);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
