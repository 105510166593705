import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AppDispatch } from '../../app/store';
import { Dashboard } from './Dashboard';
import {
  getLastReportCode,
  checkReportStatus,
  selectLoading,
  selectReport,
  setLoading,
  loadReportContent
} from './dashboardSlice';
import { ReportStatus } from './types';

type Timer = ReturnType<typeof setTimeout>;

const POLLING_INTERVAL = 3000;

export const DashboardContainer: React.FC = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const [timer, setTimer] = useState<Timer | null>(null);
  const dispatch: AppDispatch = useAppDispatch();
  const report = useAppSelector(selectReport);
  const isLoading = useAppSelector(selectLoading);

  useEffect(() => {
    if (!code) {
      dispatch(setLoading(true));
      dispatch(getLastReportCode()).then(({ payload: code }) => {
        navigate(`report/${code}`);
      });
    }
  }, [code, dispatch, navigate]);

  useEffect(() => {
    async function waitForReport(code: string) {
      const { payload: status } = await dispatch(checkReportStatus(code));

      switch (status) {
        case ReportStatus.Complete:
          dispatch(loadReportContent(code));
          return true;
        case ReportStatus.Failed:
          return false;
        case ReportStatus.Pending:
          break;
        default:
          return false;
      }

      setTimer(setTimeout(() => waitForReport(code), POLLING_INTERVAL));
    }

    if (code) {
      dispatch(setLoading(true));
      void waitForReport(code);
    }

    return () => { clearTimeout(timer as Timer) };
  }, [code, dispatch]);

  return (
    <Dashboard isLoading={isLoading} report={report} />
  );
};
