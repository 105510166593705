import React from 'react';
import { Spinner } from '../../spinner/Spinner';

import styles from './Overlay.module.scss';

export const Overlay: React.FC = () => {
  return (
    <div className={styles.DashboardOverlay}>
      <Spinner />
      <div className={styles.DashboardOverlayLabel}>Please wait while we are analyzing your org setup</div>
    </div>
  );
}
