import React from 'react';
import { OrganizationInfo, ReportMeta } from '../types';
import { format } from 'date-fns';

import styles from './Footer.module.scss';

type FooterProps = {
  meta: ReportMeta;
  org: OrganizationInfo;
};

export const Footer: React.FC<FooterProps> = ({ org, meta }) => {
  return (
    <div className={styles.DashboardFooter}>
      <span className={styles.DashboardFooterOrg}>{org.name}</span>, on {format(meta.createdAt, 'PPP')}
    </div>
  );
}
