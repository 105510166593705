import React from 'react';
import { Bar, BarChart, BarProps, Cell, ReferenceLine, XAxis, YAxis } from 'recharts';
import { useSize } from '../../../../app/hooks';
import variables from '../../../../styles/_variables.scss';
import { ScoreValue } from '../../types';
import { getScoreStyle } from '../../utils';

import styles from './GroupScore.module.scss';
import cssVars from '../../../../styles/_variables.scss';

type GroupProgressProps = {
  value: ScoreValue;
};

const barHeight = 40;
const gradeRadius = 20;
const gradeGapV = 18;
const gradeGapH = 12;
const totalHeight = barHeight + 2 * gradeRadius + gradeGapV;
const barRadius = parseInt(cssVars.borderRadiusMd as string, 10);

const getScoreVal = (dataKey: keyof ScoreValue) => (data: ScoreValue) => {
  // Make diagram more beautiful by showing small bar even when score is 0.
  const adjustment = data.maxScore * 0.15;

  return data[dataKey] + adjustment;
};

const getMaxScore = getScoreVal('maxScore');
const getScore = getScoreVal('score');

export const GroupProgress: React.FC<GroupProgressProps> = ({ value }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const size = useSize(containerRef);

  // Round right borders only if bar reaches 100%.
  const radius: BarProps['radius'] = value.score === value.maxScore ? barRadius : [barRadius, 0, 0, barRadius];
  const data = [value];
  const valueStyle = getScoreStyle(value);
  const background = { fill: variables.colorGrey200, height: barHeight, radius: barRadius, x: 0, y: 0 };

  const renderGrade = ({ x1, y1 }: { x1: number, y1: number }) => {
    const circleX = x1 - (gradeRadius + gradeGapH);
    const circleY = y1 - gradeRadius;
    const lineStroke = 2;
    const lineX = x1 - (lineStroke / 2);
    const lineY1 = y1;
    const lineY2 = circleY - (gradeRadius + gradeGapV);

    return <g>
      <circle className={styles.GroupScoreBadge} cx={circleX} cy={circleY} r={gradeRadius}/>
      <text className={styles.GroupScoreGrade}
            x={circleX}
            y={circleY}
            textAnchor="middle"
            dominantBaseline="middle"
            fill={valueStyle.color}
      >
        {valueStyle.gradeLabel}
      </text>

      {/*<text className={styles.GroupScoreLabel} x={circleX - gradeGapH - gradeRadius} y={circleY} textAnchor="end"*/}
      {/*      dominantBaseline="middle">*/}
      {/*  Total Score*/}
      {/*</text>*/}

      <line x1={lineX} x2={lineX} y1={lineY1} y2={lineY2} stroke={valueStyle.color} strokeWidth={lineStroke} />
    </g>
  };

  return (
    <div className={styles.GroupProgress} ref={containerRef}>
      <BarChart
        width={size?.width}
        height={totalHeight}
        data={data}
        layout="vertical"
        margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
      >
        <XAxis xAxisId="score" hide type="number" dataKey={getMaxScore} domain={[0, 'dataMax']} />
        <YAxis yAxisId="progress" hide type="category" />

        <YAxis yAxisId="yPos" hide type="number" domain={[0, totalHeight]} allowDataOverflow reversed />

        <Bar xAxisId="score"
             yAxisId="progress"
             dataKey={getScore}
             fill={valueStyle.color}
             background={background}
             radius={radius}
        >
          <Cell height={barHeight} x={0} y={0} />
        </Bar>

        <ReferenceLine
          xAxisId="score"
          yAxisId="yPos"
          segment={[{ x: getScore(value), y: 0 }, { x: getScore(value), y: totalHeight - barHeight }]}
          shape={renderGrade}
        />
      </BarChart>
    </div>
  );
}
