import variables from '../../styles/_variables.scss';
import { Grade, ReportDisplaySettings, ReportScoreMessages, ScoreLevel, ScoreStyle } from './types';

export const scoreStyles: { [key in Grade]: ScoreStyle } = {
  [Grade.A]: {
    grade: Grade.A,
    gradeLabel: 'A',
    color: variables.colorScoreA,
  },
  [Grade.B]: {
    grade: Grade.B,
    gradeLabel: 'B',
    color: variables.colorScoreB,
  },
  [Grade.C]: {
    grade: Grade.C,
    gradeLabel: 'C',
    color: variables.colorScoreC,
  },
  [Grade.D]: {
    grade: Grade.D,
    gradeLabel: 'D',
    color: variables.colorScoreD,
  },
  [Grade.E]: {
    grade: Grade.E,
    gradeLabel: 'E',
    color: variables.colorScoreE,
  },
  [Grade.F]: {
    grade: Grade.F,
    gradeLabel: 'F',
    color: variables.colorScoreF,
  },
};

export const reportScoreMessages: ReportScoreMessages = {
  functionalSecurity: {
    deployedProfiles: {
      [ScoreLevel.Pass]: 'You are safe with the number of profiles deployed.',
      [ScoreLevel.Warning]: 'You have moderate number of profiles deployed.',
      [ScoreLevel.Danger]: 'You have too many profiles deployed.',
    },
    deployedPermissionSets: {
      [ScoreLevel.Pass]: 'You are Safe with the number of permission set groups deployed.',
      [ScoreLevel.Warning]: 'You have moderate number of  permission set groups deployed',
      [ScoreLevel.Danger]: 'You have too many permission set groups deployed',
    },
    permissionSetsUsage: {
      [ScoreLevel.Pass]: 'Good job, you are using permission set groups.',
      [ScoreLevel.Danger]: 'You are not using Permission set groups.',
    },
    permissionSetGroupsUsage: {
      [ScoreLevel.Pass]: 'Good job, you are using permission sets.',
      [ScoreLevel.Danger]: 'You are not using permission sets.',
    },
    adminUsers: {
      [ScoreLevel.Pass]: 'You are safe with the number of System Admin Permissions assigned.',
      [ScoreLevel.Warning]: 'You have moderate number of users with System Admin Permissions assigned.',
      [ScoreLevel.Danger]: 'You have too many users with System Admin Permissions assigned.',
    },
    overdueReleaseUpdates: {
      [ScoreLevel.Pass]: 'You are safe with the number of overdue release updates.',
      [ScoreLevel.Warning]: 'You have moderate number of overdue release updates.',
      [ScoreLevel.Danger]: 'You have too many overdue release updates.',
    },
  },
  dataSecurity: {
    encryptionUsage: {
      [ScoreLevel.Pass]: 'You are safe using encryption for sensitive data.',
      [ScoreLevel.Danger]: 'You are not using encryption for sensitive data.',
    },
    dataMaskingUsage: {},
    viewAllPermission: {
      [ScoreLevel.Pass]: 'You are safe with the number of users who have access to View All permissions.',
      [ScoreLevel.Warning]: 'You have moderate number of users with View all permissions.',
      [ScoreLevel.Danger]: 'You have too many users with View All permissions.',
    },
    apiEnabledPermission: {
      [ScoreLevel.Pass]: 'You are safe with the number of users who have access to API permissions.',
      [ScoreLevel.Warning]: 'You have moderate number of users with API permissions.',
      [ScoreLevel.Danger]: 'You have too many users with API permissions.',
    },
    modifyAllPermission: {
      [ScoreLevel.Pass]: 'You are safe with the number of users who have access to Modify All permissions.',
      [ScoreLevel.Warning]: 'You have moderate number of users with Modify all permissions.',
      [ScoreLevel.Danger]: 'You have too many users with Modify All permissions.',
    },
    exportPermission: {
      [ScoreLevel.Pass]: 'You are safe with the number of users who have access to Export permissions.',
      [ScoreLevel.Warning]: 'You have moderate number of users with Export permissions.',
      [ScoreLevel.Danger]: 'You have too many users with Export permissions.',
    },
    publicObjects: {
      [ScoreLevel.Pass]: 'You don\'t have objects with default public access.',
      [ScoreLevel.Danger]: 'You have some objects with default public access.',
    },
  },
  externalAccess: {
    remoteSiteSettings: {
      [ScoreLevel.Pass]: 'You don\'t have any remote site settings and your data is safe.',
      [ScoreLevel.Danger]: 'At least one external site is added as a remote site setting.',
    },
    openIpAddresses: {
      [ScoreLevel.Pass]: 'You don\'t have any trusted IP ranges.',
      [ScoreLevel.Danger]: 'You have a number of IPs from which users can log in without additional steps of verification of their identity.',
    },
    dataExposingPackages: {
      [ScoreLevel.Pass]: 'You don\'t have any installed packages that potentially can send data to third-party apps.',
      [ScoreLevel.Danger]: 'You have packages that potentially can send your data to third-party apps.',
    },
    exportDataPermission: {
      [ScoreLevel.Pass]: 'You are safe with the number of users who has Data Export permission.',
      [ScoreLevel.Warning]: 'You have moderate number of  users who has Data Export permission.',
      [ScoreLevel.Danger]: 'You have too many users with Data Export Permission.',
    },
    namedCredentials: {
      [ScoreLevel.Pass]: 'You don\'t use named credentials to access external systems to send data. You are safe.',
      [ScoreLevel.Danger]: 'You use named credentials to access external systems and potentially send your data.',
    },
    externalPublicObjects: {
      [ScoreLevel.Pass]: 'Your data is safe. You don\'t have objects with default Public permission for Portal/Site Users.',
      [ScoreLevel.Danger]: 'You have a number of objects with default Public permission for Portal/Site Users. You are at risk of unauthorized access to your data.',
    },
    externalSharingRules: {
      [ScoreLevel.Pass]: 'You don\'t have sharing rules for Portal/Site Users. Your data is safe.',
      [ScoreLevel.Danger]: 'You have a number of sharing rules for Portal/Site Users. You are at risk of unauthorized access to your data.',
    },
    externalQueuesAndGroups: {
      [ScoreLevel.Pass]: 'Your data is safe. You don\'t have queues or public groups with Portal/Site users.',
      [ScoreLevel.Danger]: 'You have a queues or public groups with public/site users. You are at risk of unauthorized access to your data.',
    },
  },
  softwareAnalysis: {
    managedPackages: {
      [ScoreLevel.Pass]: 'You are safe with unauthorized managed packages.',
      [ScoreLevel.Danger]: 'You have unauthorized managed packages.',
    },
    unmanagedPackages: {
      [ScoreLevel.Pass]: 'You are safe with unauthorized unmanaged packages.',
      [ScoreLevel.Danger]: 'You have unauthorized unmanaged packages.',
    },
    staticLibraries: {
      [ScoreLevel.Pass]: 'You are safe with static libraries.',
      [ScoreLevel.Danger]: 'You use static libraries.',
    },
    codeCoverage: {
      [ScoreLevel.Pass]: 'You have a good level of test coverage.',
      [ScoreLevel.Warning]: 'The percent of test coverage close to limit.',
      [ScoreLevel.Danger]: 'The percent of test coverage is low.',
    },
  },
  organizationSecurity: {
    sessionTimeout: {
      [ScoreLevel.Pass]: 'You are safe with session time out settings.',
      [ScoreLevel.Warning]: 'You have a moderate session time out setting.',
      [ScoreLevel.Danger]: 'You have an unsafe session timeout setting.',
    },
    forceLogoutOnTimeout: {
      [ScoreLevel.Pass]: 'You have a safe force logout on session timeout setting.',
      [ScoreLevel.Warning]: 'You have a moderate force logout on session timeout setting.',
      [ScoreLevel.Danger]: 'You have an unsafe force logout on session timeout setting.',
    },
    sessionDomainLock: {
      [ScoreLevel.Pass]: 'You have a safe number of lock sessions from the domain which they were first used.',
      [ScoreLevel.Warning]: 'You have a moderate number of lock sessions from the domain which they were first used.',
      [ScoreLevel.Danger]: 'You have an unsafe number of lock sessions from the domain which they were first used.',
    },
    sessionIpLock: {
      [ScoreLevel.Pass]: 'You have a safe number of lock sessions for the IP address from which they originated.',
      [ScoreLevel.Warning]: 'You have a moderate number of lock sessions for the IP address from which they originated.',
      [ScoreLevel.Danger]: 'You have an unsafe number of lock sessions for the IP address from which they originated.',
    },
    trustedIpRanges: {
      [ScoreLevel.Pass]: 'You have a safe number of trusted IP ranges for your organization.',
      [ScoreLevel.Danger]: 'You have an unsafe number of trusted IP ranges for your organization.',
    },
    invalidLoginAttempts: {
      [ScoreLevel.Pass]: 'You have a safe number for maximum invalid login attempts.',
      [ScoreLevel.Danger]: 'You have an unsafe number for maximum invalid login attempts.',
    },
    expiredCertificates: {
      [ScoreLevel.Pass]: 'You have no expired certificates.',
      [ScoreLevel.Danger]: 'You have too many expired certificates.',
    },
    passwordComplexity: {
      [ScoreLevel.Pass]: 'You have a safe password complexity requirement.',
      [ScoreLevel.Warning]: 'You have a moderate password complexity requirement.',
      [ScoreLevel.Danger]: 'You have an unsafe password complexity requirement.',
    },
    passwordExpirationDays: {
      [ScoreLevel.Pass]: 'You have a safe password expiration timeout.',
      [ScoreLevel.Warning]: 'You have a moderate password expiration timeout.',
      [ScoreLevel.Danger]: 'You have an unsafe password expiration timeout.',
    },
    minPasswordLength: {
      [ScoreLevel.Pass]: 'You have a safe minimum password length.',
      [ScoreLevel.Danger]: 'You have an unsafe minimum password length.',
    },
    passwordLifetime: {
      [ScoreLevel.Pass]: 'You have a safe password lifetime.',
      [ScoreLevel.Danger]: 'You have an unsafe password lifetime.',
    },
    httpOnlyRequired: {
      [ScoreLevel.Pass]: 'Your cookies are safe with HttpOnly attribute.',
      [ScoreLevel.Danger]: 'You don\'t use HttpOnly attribute to protect your cookies.',
    },
    enforceLoginIpRanges: {
      [ScoreLevel.Pass]: 'You have a safe enforce IP ranges on every request setting.',
      [ScoreLevel.Danger]: 'You have an unsafe enforce IP ranges on every request setting.',
    },
  },
};

export const reportDisplaySettings: ReportDisplaySettings = {
  functionalSecurity: {
    title: 'Functional security',
    icon: 'badge',
  },
  dataSecurity: {
    title: 'Data security',
    icon: 'database',
  },
  externalAccess: {
    title: 'External access',
    icon: 'group',
  },
  softwareAnalysis: {
    title: 'Software composition analysis',
    icon: 'privacy',
  },
  organizationSecurity: {
    title: 'Org security',
    icon: 'building',
  },
};
