import React from 'react';

import styles from './Cards.module.scss';

type CardsProps = {
  children?: React.ReactNode;
};

export const Cards: React.FC<CardsProps> = ({ children }) => {
  return (
    <div className={styles.DashboardCards}>
      {children}
    </div>
  );
}
