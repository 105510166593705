import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { LoginContainer } from './features/auth/LoginContainer';
import { RequireAuth } from './features/auth/RequireAuth';
import { DashboardContainer } from './features/dashboard/DashboardContainer';
import { WithNav } from './features/layout/WithNav';
import { WithoutNav } from './features/layout/WithoutNav';
import { ReactComponent as Icons } from './images/icons.svg';

function App() {
  return (
    <>
      <div style={{display: 'none'}}>
        <Icons />
      </div>

      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route element={<WithoutNav />}>
            <Route path="/login" element={<LoginContainer />} />
          </Route>

          <Route element={<WithNav />}>
            <Route path="/" element={<RequireAuth><DashboardContainer /></RequireAuth>} />
            <Route path="/report/:code" element={<RequireAuth><DashboardContainer /></RequireAuth>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
