import React from 'react';
import { PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';
import { useSize } from '../../../../app/hooks';
import { ScoreValue } from '../../types';
import { getScoreStyle } from '../../utils';
import { Card } from '../Card';
import { CardProps } from '../types';

import cssVars from '../../../../styles/_variables.scss';
import styles from './TotalScore.module.scss';

type TotalScoreCardProps = CardProps & {
  value?: ScoreValue;
};

const gradeRadius = 25;
const gradeGap = 6;
const getScoreVal = (dataKey: keyof ScoreValue) => (data: ScoreValue) => {
  // Make diagram more beautiful by showing small bar even when score is 0.
  const adjustment = data.maxScore * 0.05;

  return data[dataKey] + adjustment;
};

export const TotalScoreCard: React.FC<TotalScoreCardProps> = ({ value, icon }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const size = useSize(containerRef);

  if (!value) {
    return null;
  }

  const data = [value];
  const background = { fill: cssVars.colorGrey200 };
  const valueStyle = getScoreStyle(value);

  const renderGrade = ({ cx, cy }: { cx: number, cy: number }) => (
    <g transform={`translate(${cx}, ${cy})`}>
      <circle className={styles.TotalScoreBadge} cx={0} cy={-gradeRadius - gradeGap / 2} r={gradeRadius}/>
      <text className={styles.TotalScoreGrade}
            x={0}
            y={-gradeRadius - gradeGap / 2}
            textAnchor="middle"
            dominantBaseline="middle"
            fill={valueStyle.color}
      >
        {valueStyle.gradeLabel}
      </text>

      <text className={styles.TotalScoreLabel} x={0} y={gradeGap / 2} textAnchor="middle" dominantBaseline="text-before-edge">
        Total Score
      </text>
    </g>
  );

  return (
    <Card title="Total score" icon={icon ?? 'donut-chart'}>
      <div className={styles.TotalScoreContainer} ref={containerRef}>
        <ResponsiveContainer aspect={1} height="100%" width={size?.width} maxHeight={395} className={styles.TotalScoreChart}>
          <RadialBarChart
            innerRadius="78%"
            outerRadius="100%"
            data={data}
            startAngle={450}
            endAngle={90}
            barGap={0}
            barCategoryGap={0}
            margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
          >
            <PolarAngleAxis type="number" dataKey={getScoreVal('maxScore')} angleAxisId={0} tick={false} />
            <RadialBar
              isAnimationActive={false}
              dataKey={getScoreVal('score')}
              fill={valueStyle.color}
              cornerRadius={cssVars.borderRadiusMd}
              background={background}
              label={renderGrade}
            />
          </RadialBarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
}
