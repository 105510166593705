import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { RootState } from '../../app/store';
import { apiRequest } from '../api/apiSlice';
import { OrganizationReport, ReportResponse, ReportStatus } from './types';

export interface DashboardState {
  code: string | null;
  report: OrganizationReport | null;
  loading: boolean;
}

const initialState: DashboardState = {
  code: null,
  report: null,
  loading: true,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<DashboardState['loading']>) => {
      state.loading = action.payload;
    },
    setCode: (state, action: PayloadAction<DashboardState['code']>) => {
      state.code = action.payload;
    },
    setReport: (state, action: PayloadAction<DashboardState['report']>) => {
      state.report = action.payload;
    },
  },
});

export const { setLoading, setCode, setReport } = dashboardSlice.actions;

export const selectLoading = (state: RootState) => state.dashboard.loading;
export const selectCode = (state: RootState) => state.dashboard.code;
export const selectReport = (state: RootState): DashboardState['report'] => {
  const report = state.dashboard.report;

  if (!report) {
    return null;
  }

  return {
    ...report,
    meta: {
      ...report.meta,
      createdAt: report.meta.createdAt instanceof Date ? report.meta.createdAt : parseISO(report.meta.createdAt),
    },
  };
};

const loadReport = createAsyncThunk<ReportResponse, string, { state: RootState }>(
  'dashboard/load-report',
  async (url, { dispatch }) => {
    dispatch(setLoading(true));

    const apiResult = await dispatch(apiRequest(async (http) => {
      return http.get(url);
    }));

    return (apiResult.payload as AxiosResponse<ReportResponse>).data;
  }
);

export const createReport = createAsyncThunk<ReportResponse, never, { state: RootState }>(
  'dashboard/create-report',
  async (url, { dispatch }) => {
    dispatch(setLoading(true));

    const apiResult = await dispatch(apiRequest(async (http) => {
      return http.post(url);
    }));

    return (apiResult.payload as AxiosResponse<ReportResponse>).data;
  }
);

export const getLastReportCode = createAsyncThunk<string, never, { state: RootState }>(
  'dashboard/last-report',
  async (_, { dispatch }) => {
    const { payload: response } = await dispatch(loadReport('report')) as { payload: ReportResponse };

    return response.code;
  }
);

export const checkReportStatus = createAsyncThunk<ReportStatus, string, { state: RootState }>(
  'dashboard/check-status',
  async (code, { dispatch }) => {
    const { payload: response } = await dispatch(loadReport(`report/${code}/status`)) as { payload: ReportResponse };

    return response.status;
  }
);

export const loadReportContent = createAsyncThunk<void, string, { state: RootState }>(
  'dashboard/load-content',
  async (code, { dispatch }) => {
    const { payload: response } = await dispatch(loadReport(`report/${code}`)) as { payload: ReportResponse };

    dispatch(setLoading(false));
    dispatch(setCode(response.code));
    dispatch(setReport(response.report));
  }
);

export default dashboardSlice.reducer;

// export const reportApi = createApi({
//   reducerPath: 'report',
//   baseQuery: fetchBaseQuery({
//     baseUrl: 'http://localhost:3001',
//     prepareHeaders: (headers, { getState }) => {
//       const token = selectAuthToken(getState() as RootState);
//
//       return {
//         ...headers,
//         ...(token ? { Authorization: `Bearer ${token}` } : undefined)
//       }
//     },
//   }),
//   endpoints: (builder) => ({
//     getLastReport: builder.query<number, null>({
//       query: () => 'report',
//     }),
//   }),
// })
//
// // Export hooks for usage in functional components
// export const { useGetLastReportQuery } = reportApi
//
// const foo = reportApi.useGetLastReportQuery(null, { pollingInterval: 3000 });
