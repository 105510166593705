import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from "axios";
import { AppThunk, RootState } from "../../app/store";
import { apiRequest } from "../api/apiSlice";

export interface AuthState {
  token: string | null;
  status: 'guest' | 'user';
}

const initialState: AuthState = {
  token: null,
  status: 'guest',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
      state.status = action.payload ? 'user' : 'guest';
    },
    setStatus: (state, action: PayloadAction<AuthState['status']>) => {
      state.status = action.payload;
    },
  },
});

export const { setToken, setStatus } = authSlice.actions;

export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectAuthStatus = (state: RootState) => state.auth.status;

export const salesforceAuthStart = (sandbox: boolean): AppThunk<Promise<string>> => async (dispatch) => {
  const apiResult = await dispatch(apiRequest(async (http) => {
    return http.get('auth/sf/login', { params: { sandbox } });
  }));

  const response = apiResult.payload as AxiosResponse<{ url: string }>;

  return response.data.url;
};

export const salesforceAuthComplete = (code: string, sandbox: boolean): AppThunk => async (dispatch) => {
  const apiResult = await dispatch(apiRequest(async (http) => {
    return http.post('auth/sf/login', { code, sandbox });
  }));

  const response = apiResult.payload as AxiosResponse<{ accessToken: string }>;

  if (response.data.accessToken) {
    dispatch(setToken(response.data.accessToken));
  } else {
    dispatch(setToken(null));
  }
};

export default authSlice.reducer;
