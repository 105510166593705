import React from 'react';
import { Icon } from '../../icon/Icon';
import { IconName, IconSize } from '../../icon/types';

import styles from './Cards.module.scss';

type CardProps = {
  label: string;
  icon?: IconName;
};

export const CardHeader: React.FC<CardProps> = ({ label, icon }) => {
  return (
    <div className={styles.DashboardCardHeader}>
      { icon && <Icon className={styles.DashboardCardHeaderIcon} name={icon} size={IconSize.Larger} /> }
      <span>
        {label}
      </span>
    </div>
  );
}
