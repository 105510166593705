import React from 'react';
import logo from '../../images/logo-dark.svg';

import styles from './Navbar.module.scss';

export const Navbar: React.FC = () => {
  return (
    <div className={styles.Navbar}>
      <img alt={''} src={logo} className={styles.NavbarLogo} />
    </div>
  );
}
