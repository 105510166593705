import React from 'react';
import logo from '../../images/logo.svg';

import styles from './Spinner.module.scss';

export const Spinner: React.FC = () => {
  return (
    <div className={styles.Spinner}>
      <img alt="Spinner" src={logo} className={styles.SpinnerLogo} />
    </div>
  );
}
