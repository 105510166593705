import * as CSS from 'csstype';
import { IconName } from '../icon/types';

export const enum Grade {
  A = 5,
  B = 4,
  C = 3,
  D = 2,
  E = 1,
  F = 0,
}

export enum ScoreLevel {
  Pass = 0,
  Warning = 1,
  Danger = 2,
}

export interface Score {
  score: number;
  maxScore: number;
  level: ScoreLevel;
}

export type ScoreValue = Pick<Score, 'score' | 'maxScore'>;

export interface ReportGroup {
  [key: string]: Score;
}

export interface FunctionalSecurityGroup extends ReportGroup {
  deployedProfiles: Score;
  deployedPermissionSets: Score;
  permissionSetsUsage: Score;
  permissionSetGroupsUsage: Score;
  adminUsers: Score;
  overdueReleaseUpdates: Score;
}

export interface DataSecurityGroup extends ReportGroup {
  encryptionUsage: Score;
  dataMaskingUsage: Score;
  viewAllPermission: Score;
  apiEnabledPermission: Score;
  modifyAllPermission: Score;
  exportPermission: Score;
  publicObjects: Score;
}

export interface ExternalAccessGroup extends ReportGroup {
  remoteSiteSettings: Score;
  openIpAddresses: Score;
  dataExposingPackages: Score;
  exportDataPermission: Score;
  namedCredentials: Score;
  externalPublicObjects: Score;
  externalSharingRules: Score;
  externalQueuesAndGroups: Score;
}

export interface SoftwareAnalysisGroup extends ReportGroup {
  managedPackages: Score;
  unmanagedPackages: Score;
  staticLibraries: Score;
  codeCoverage: Score;
}

export interface OrganizationSecurityGroup extends ReportGroup {
  sessionTimeout: Score;
  forceLogoutOnTimeout: Score;
  sessionDomainLock: Score;
  sessionIpLock: Score;
  trustedIpRanges: Score;
  invalidLoginAttempts: Score;
  expiredCertificates: Score;
  passwordComplexity: Score;
  passwordExpirationDays: Score;
  minPasswordLength: Score;
  passwordLifetime: Score;
  httpOnlyRequired: Score;
  enforceLoginIpRanges: Score;
}

export interface OrganizationInfo {
  name: string;
}

export interface ReportMeta {
  createdAt: Date;
}

export interface OrganizationReport {
  meta: ReportMeta;
  organization: OrganizationInfo;
  groups: {
    functionalSecurity: FunctionalSecurityGroup;
    dataSecurity: DataSecurityGroup;
    externalAccess: ExternalAccessGroup;
    softwareAnalysis: SoftwareAnalysisGroup;
    organizationSecurity: OrganizationSecurityGroup;
  };
}

export interface ScoreStyle {
  grade: Grade;
  gradeLabel: string;
  color: CSS.Properties['color'];
}

export type ScoreLevelMessages = {
  [key in ScoreLevel]?: string;
}

export type ReportScoreMessages = {
  [orgKey in keyof OrganizationReport['groups']]: {
    [groupKey in keyof OrganizationReport['groups'][orgKey]]: ScoreLevelMessages;
  };
};

export type GroupDisplaySettings = {
  title: string;
  icon: IconName;
};

export type ReportDisplaySettings = {
  [key in keyof OrganizationReport['groups']]: GroupDisplaySettings;
}

export enum ReportStatus {
  Created = 'Created',
  Pending = 'Pending',
  Complete = 'Complete',
  Failed = 'Failed',
}

export type ReportResponse = {
  code: string;
  status: ReportStatus;
  report: OrganizationReport | null;
}
