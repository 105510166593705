export enum IconSize {
  Small = 'small',
  Medium = 'medium',
  Larger = 'larger',
  Large = 'large',
}

export type IconName =
  'donut-chart' |
  'badge' |
  'database' |
  'group' |
  'privacy' |
  'building' |
  'check-circle' |
  'warning' |
  'close-circle';
