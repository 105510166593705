import React from 'react';

import styles from './GroupScore.module.scss';

type Props = {
  children?: React.ReactNode;
};

export const ScoreDetailsList: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.ScoreDetailsList}>
      {children}
    </div>
  );
}
