import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { AppDispatch, RootState } from '../../app/store';
import { selectAuthToken, setToken } from '../auth/authSlice';

export interface ApiState {
  runningRequests: number;
}

const initialState: ApiState = {
  runningRequests: 0,
};

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
let interceptorsReady = false;

function initClient(getState: () => RootState, dispatch: AppDispatch) {
  if (!interceptorsReady) {
    client.interceptors.request.use((request) => {
      const token = selectAuthToken(getState());

      if (token) {
        request.headers = {
          ...request.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      return request;
    });

    client.interceptors.response.use(undefined, (response: unknown) => {
      if (axios.isAxiosError(response)) {
        if (response.response?.status === 401) {
          dispatch(setToken(null));
        }
      }

      return response;
    });

    interceptorsReady = true;
  }
}

export const apiRequest = createAsyncThunk<AxiosResponse, ((http: AxiosInstance) => Promise<AxiosResponse>), { state: RootState }>(
  'api/request',
  (callback, { getState, dispatch }) => {
    initClient(getState, dispatch as AppDispatch);

    return callback(client);
  }
);

export const apiSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    incrementRequests: (state) => {
      state.runningRequests++;
    },
    decrementRequests: (state) => {
      state.runningRequests--;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(apiRequest.pending, (state) => {
        state.runningRequests++;
      })
      .addCase(apiRequest.rejected, (state) => {
        state.runningRequests--;
      })
      .addCase(apiRequest.fulfilled, (state) => {
        state.runningRequests--;
      });
  },
});

export default apiSlice.reducer;
