import React from 'react';
import { IconName } from '../../icon/types';
import { CardHeader } from './CardHeader';

import styles from './Cards.module.scss';

type CardProps = {
  title: string;
  icon?: IconName;
  children: React.ReactNode;
};

export const Card: React.FC<CardProps> = ({ title, icon, children }) => {
  return (
    <div className={styles.DashboardCard}>
      <CardHeader label={title} icon={icon}></CardHeader>
      {children}
    </div>
  );
}
