import React from 'react';
import { Outlet } from 'react-router-dom';

import styles from './Layout.module.scss';

export const WithoutNav: React.FC = () => {
  return (
    <div className={styles.Container}>
      <Outlet />
    </div>
  )
};
