import { Button, Spinner } from '@salesforce/design-system-react';
import React from 'react';
import logoFull from '../../images/logo-full.png';

import styles from './Auth.module.scss';

export type LoginProps = {
  onLogin: () => void;
  onSandboxLogin: () => void;
  pending?: boolean;
};

export const Login: React.FC<LoginProps> = ({ onLogin, onSandboxLogin, pending }) => {
  return (
    <div className={styles.LoginContainer}>
      <div className={styles.LoginCard}>
        <img alt={''} src={logoFull} className={styles.LoginLogo} />
        <div>
          <h1>Authorize Organization</h1>
          <p>Login please via Salesforce for your security.</p>
        </div>
        { pending && <Spinner size="small" variant="brand" hasContainer={false} isInline /> }

        { !pending && <div className={styles.LoginButtons}>
          <Button label='Log In via Salesforce' variant='brand' onClick={onLogin} />
          <Button label='Log In via Salesforce (Sandbox)' variant='brand' onClick={onSandboxLogin} />
        </div>}
      </div>
    </div>
  );
}
